
.dropbtn {
    background-color: #34393c;
    color: white;
    padding:16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus{
    background-color: rgb(161, 47, 161);
}

.dropdown {
    position: relative;
    display: inline-block
}

.dropdown-content {
    display:none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
}

/* options inside dropdown */
.dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block
}

.dropdown-content p:hover {background-color: #ddd;}

/* show dropdown (JS to add this class to the .dropdown-content container on click */
.show {display: block;}
body{
    background: rgb(207, 140, 224);
}
.outerContainer{
    background: rgb(207, 140, 224);
    color: white;
    height: 900px;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    size: 100px;
    
}
h1 {
    color: white;
    font-size: 40px;
    font-weight: bold;
    text-align: right;
    margin-top: 5px;
    margin-right: 10px;
    z-index: 3;
}
header{
    
    background-color:rgb(161, 47, 161);
    border-bottom-style: solid;
    border-bottom-color: purple;
    border-bottom-width: 3px;
    height: 65px;
    
    position: fixed;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    top: 0;
    left: 0;
    z-index: 1;
}
#logo{
    position: relative;
    top: 60px;
    z-index: 2;
}

#welcome{
    color: white;
    position: relative; 
    top: 50px;
    font-family:cursive;
    font-size: 50px;
}
#SignUp{
    position: relative; 
    top: 50px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 30px;
    border-radius: 20px;
    background-color: white;
}
#LogIn{
    position: relative; 
    top: 50px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 30px;
    border-radius: 10px;
    background-color:white;
}

#Info1{
    position:relative;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 25px;
    top: 50px;
}
#infoheader{
    margin-top: 5px;
}

#infoButton{
    position: relative; 
    top: 50px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 30px;
    border-radius: 20px;
    background-color:white;
}

.welcome {
    float: clear;
    position: relative; 
    top: 5px;
    font-family:cursive;
    font-size: 40px;
    color: white;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: white;
}
.User_info {
    float: clear;
    padding-left: 20px;
}
.background {
    /* background-color: rgba(230, 177, 230, 0.253); */
    background-size: cover;
}

.right{
    float:right;
}
i {
    position: fixed;
    z-index: 2;
}
.heart {
    top: 40px;
    left: 15px;
    width: 3.75%;
}
.comment {
    top: 40px;
    left: 60px;
    width: 3.75%;
}
.gear {
    top: 40px;
    left: 110px;
    width: 3.75%;
}
.userName {
    position: fixed;
    top: 15px;
    left: 170px;
    font-size: xx-large;
    z-index: 2;
    color: white;
}
.chat {
    color: green;
    width: 3.75%;
}
.skip {
    color: yellow;
    width: 3.75%;
}
.no {
    color: red;
    width: 3.75%;
}
.current {
    color: white;
}
.logo {
    position: fixed;
    align-self: right;
    right: 210px;
    top:2.5px;
    width: 5%;
}
.settings {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.edit {
    margin-top: 80px;
    margin-left: 40px;
    /* float: left;
    width: 32%; */
    display: inline;
    
}
.blocked {
    margin-top: 80px;
    padding-left: 40px;
    margin-right: 40px;
    display: inline;
    /* float: left;
    width: 32%; */
    margin-left: 0px;
    border-left-style: solid;
    border-left-color: purple;
    border-left-width: 2px;
}
.matched {
    margin-top: 80px;
    padding-left: 40px;
    display: inline;
    margin-right: 40px;
    /* float: left;
    width: 32%; */
    margin-left: 0px;
    border-left-style: solid;
    border-left-color: purple;
    border-left-width: 2px;
}
.scrollbox {
    height:500px;
    width:360px;
    border:1px solid #ccc;
    overflow:auto;
}
.userbox {
    width:355px;
    height: 100px;
    border: 2px solid #ccc;


}
.icon {
    color: black;
    text-decoration: none;
    box-shadow: none;
}
h3{
    margin-left: 10px;
}
.matches {
    margin-top:200px;
}
.match {
    position: relative;
    border: solid black 3px;

}
.actions {
    align-items: center;
    background: none;
    margin: 0;
    padding: 0;
    border: none;

}

.chat_users {
    display: flex;
    position: relative;
    /* align-items: center; */
    /* text-align: center; */
    font-size: 40px;
    border: #34393c;
    border-width: 20px;
    top: 80px;
    flex-wrap: nowrap;
    flex-direction: column;
    
}
/* #name a:link, #name a:visited{
    padding:20px;
    color: black;
    background-color: transparent;
    text-decoration: none;
    text-align: center;
} */

#me {
    text-align: right;
    display: flex;
    justify-content:flex-end;
}

#you {
    text-align: left;
    display: flex;
    justify-content: flex-start;
}


.butt form {
    
    padding-top: 100px;

    text-align:right;



}

.chip{
    display: flex;

}
a {
    text-decoration: none;
    box-shadow: none;
    
}

h6 {
    font-size: 0.9rem;
    font-weight:normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(88, 86, 86);
}

h3 {
    padding-bottom: 20px;
    text-align: center;
    font-weight: bolder;
}

h4 {
    padding-bottom: 20px;
    text-align: center;
    font-weight: bolder;
}

.wait {
    flex-wrap: wrap;
    padding-top: 50px;

    padding-bottom: 50px;
    top:2000px;
    height: 200px;
    text-align: center;
    
}

textarea {
    padding-left: 100px;
}

/* .name {
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
} */

.grid-container {
    display: inline-grid;
}


/* how can i get the textbox to the right emily??? */